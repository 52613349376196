<div class="layout-bottom-bar justify-content-around align-items-center" style="height: 50px;" :style="{'background-color' : app_base_data.second_theme_color}">
    
    <div class="flex">
        
        <div v-for="(item, index) in menu_items" :key="index" 
            class="flex align-items-center justify-content-center ">
            <app-sub-menu :app_base_data="app_base_data" :menu="item"></app-sub-menu>
        </div>
    </div>
    

</div>