import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-main-container" }
const _hoisted_2 = { class: "layout-main" }

export function render(_ctx, _cache) {
  const _component_MenuBar = _resolveComponent("MenuBar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_app_bottom_menu = _resolveComponent("app-bottom-menu")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["layout-wrapper", _ctx.containerClass])
  }, [
    _createVNode(_component_MenuBar, {
      app_base_data: _ctx.app_base_data,
      menu_items: _ctx.getMenus
    }, null, 8, ["app_base_data", "menu_items"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      (_ctx.isMobile && _ctx.getMenus.length > 0)
        ? (_openBlock(), _createBlock(_component_app_bottom_menu, {
            key: 0,
            app_base_data: _ctx.app_base_data,
            menu_items: _ctx.getMenus
          }, null, 8, ["app_base_data", "menu_items"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}