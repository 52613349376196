import {Component, Vue} from 'vue-facing-decorator';
import Loading from 'vue-loading-overlay';
//import axn from '@/utils/axios_non';

@Component({
    name: 'app-root',
    components: {       
        'loading'    : Loading,
    }
})
export default class App extends Vue{
    isAppLoading = false;
    
    async mounted() {
        /**
        try {
            this.isAppLoading = true;
            const response = await axn.get('admin/config/base/get_base_config');
            if (response.data.status) {
                this.$store.commit('baseData/setBaseData', response.data.data.profile);
            }
            this.isAppLoading = false;
        } catch (error) {
            this.isAppLoading = false;
        }
         */
        
        if(this.$route.path && this.$route.path != '/'){
            this.$router.push(this.$route.path);
        }else{
            this.$router.push('/home');
        }
    }
}