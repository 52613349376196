const Syarat = {
    namespaced: true,
    state: {
        list : [
            `Pendaftaran antrian rawat jalan Online dibuka setiap hari dari H-5 sebelum tanggal periksa dan ditutup pada hari kunjungan pada pukul 12.00 Siang Wib, Jum'at jam 11.00`,
            `Pendaftaran online hanya untuk pasien rawat jalan Poliklinik yang sudah mempunyai nomer pasien, atau nomor rekam medis di RSUD Dr. Soeroto Ngawi, tidak berlaku bagi pasien rawat jalan IGD kecuali pasien umum/membayar.`,
            `Untuk Pasien yang Belum memiliki Nomor Rekam Medis (RM) / nomor Pasien dan Bagi Pasien yang lupa Nomor RM nya silahkan WA ke 085645136915 <b><a href="https://api.whatsapp.com/send?phone=+6285645136915">KLIK DISINI UNTUK KIRIM WA</a></b>`,
            `Pasien BPJS WAJIB mempunyai surat rujukan dari FASKES Tingkat I ( puskesmas, klinik, atau dokter keluarga ).`,
            `Khusus pasien BPJS wajib (minimal satu kali) pernah mendaftar di RSUD Dr. Soeroto Ngawi menggunakan Surat Rujukan dari FASKES Tingkat 1`,
            `Pendaftaran Online hanya boleh dilaksanakan sekali dan berlaku satu kali pada selang waktu 7 hari menurut aturan BPJS dipoli yang sama.`,
            `Bagi pasien yang mempunyai buku kendali obat mohon dibawa saat kontrol dan ditujukan kepada perawat poli yang dituju dan apabila buku tidak dibawa obat akan diberikan hanya untuk 7 hari saja.`
        ]
    },
};
export default Syarat;