<div class="text-center text-xl font-bold">{{app_base_data.company_name}}</div>
<div class="text-center mt-2"><b>Antrian Rawat Jalan</b></div>
<div class="text-center  font-italic text-base">
    Silahkan Datang Jam {{data.jam}}
</div>
<div class="text-center font-bold text-7xl">{{data.antrian_poli}}</div>



<div class="text-center">--( {{data.antrian_admin}} )--</div>
<div class="text-center font-bold text-base">
    {{formatDate(data.tgl_pemeriksaan)}} - ({{data.penjamin}}) <br>
    {{data.nama_poli}}
</div>

<div class="text-center font-bold font-italic text-sm mt-2">
    RM : {{data.nomor_rm}} <br>
    <span v-if="data.kode_bpjs">BPJS : {{data.kode_bpjs}} <br></span>
    {{data.nama}} <br>
</div>

<div v-if="data.finger.status" class="text-center  font-italic text-base">
    {{data.finger.msg}}
</div>

<div v-if="data.sumber" class="text-center  font-italic text-sx mt-2">
    mendaftar melalui {{data.sumber}}
</div>