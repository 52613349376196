
<router-link :to="menu.to || ''" 
        v-ripple 
      
        class=" align-items-center  text-center pt-2 " 
        :style="[ 
            path == menu.to ? 
                { 'width' : '72px' , 'height': '36px' } : {'width' : '65px' , 'height': '50px' } , 
                {'color':app_base_data.main_theme_color} 
        ]"
        :class=" path == menu.to ? ' mb-3' : 'm-1' "
        active-class="my-active  "
    >

    

        <div class="flex flex-column">
            <div class="flex align-items-center justify-content-center mb-1  font-bold" v-if="path == menu.to" >
                <small class="text-xs">{{ menu.label }}</small>
            </div>

            <div class="flex align-items-center justify-content-center "
                :class=" path == menu.to ? 'text-2xl' : '' "
            >
                <font-awesome-icon :icon="menu.icon"  /> 
            </div>

            <div class="flex align-items-center justify-content-center mt-1  font-bold" v-if="path != menu.to" >
                <small class="text-xs">{{ menu.label }}</small>
            </div>
        </div>

 
   
</router-link>