<template>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"  loader="bars"/>

    <p class="font-bold">
        <h5>Pendaftaran Rawat Jalan Online</h5>
        Pendaftaran Online adalah fitur yang digunakan untuk mendaftar dilayanan rawat jalan.
    </p>

    <p-message  severity="info" :closable="false">Khusus Pasien Yang sudah Memiliki nomor Rekamedis / RM / Nomor Pasien</p-message>

    <p-message v-if="error" severity="error">{{error}}</p-message>
    <p-message v-if="success" severity="success">{{success}}</p-message>

    <!------------------------------------------------------------------------------------------>
    <div class="grid align-items-center justify-content-center">
        <!-------------------------------------------------------------------------------------->
        <div class="hidden lg:block col-12  md:col-12 lg:col-3 xl:col-3" v-if="isIklan">
            <p-card class="mb-5 card">
                <template #content>
                    <p class="m-0">
                       
                    </p>
                </template>
            </p-card>
        </div>
        <!-------------------------------------------------------------------------------------->
        
        <!-------------------------------------------------------------------------------------->
        <!-------------------------------------------------------------------------------------->
        <div class="col-12  md:col-12 lg:col-6 xl:col-6 ">
            <!--Syarat-------------------------------------------------------------------------->
            <p-card class="mb-5 card" v-if="posisi == 1">
                <template #title><font-awesome-icon :icon="['fas', 'square-check']" /> <span class="text-base md:text-xl">Syarat Pendaftaran Online</span></template>
                <template #content>
                    <hr>
                    <p class="m-0">
                        <ol v-if="isLodingSyarat">
                            <li class="mb-4">
                                <p-skeleton height="2rem" class="mb-2" borderRadius="16px" />
                            </li>
                            <li class="mb-4">
                                <p-skeleton height="2rem" class="mb-2" borderRadius="16px" />
                            </li>
                            <li class="mb-4">
                                <p-skeleton height="2rem" class="mb-2" borderRadius="16px" />
                            </li>
                            <li class="mb-4">
                                <p-skeleton height="2rem" class="mb-2" borderRadius="16px" />
                            </li>
                        </ol>
                        <ol v-else>
                            <li class="text-base md:text-lg mb-3" v-for="(item, index) in syarat" :key="index" v-html="item"></li>
                        </ol>
                    </p>
                    <!-------------------------------------------------------------------------->
                    <hr>
                    <div class="flex justify-content-center flex-wrap">
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="lanjut" severity="success" rounded outlined>Lanjut <font-awesome-icon :icon="['fas', 'circle-right']" /></p-button>
                        </div>
                    </div>
                    <!-------------------------------------------------------------------------->
                </template>
            </p-card>
            <!--Syarat-------------------------------------------------------------------------->

            <!--Jenis / Penjamin---------------------------------------------------------------->
            <p-card class="mb-5 card" v-if="posisi == 2">
                <template #title><font-awesome-icon :icon="['fas', 'money-bills']" /> <span class="text-base md:text-xl">Pilih Jenis / Status Pendaftaran</span></template>
                <template #content>
                    <span class="text-base font-bold">Silahkan Pilih</span>
                    <hr>
                    <p class="m-0">
                        <div class="flex justify-content-around">
                            <div @click="pilihJenis('UMUM')" class="flex align-items-center justify-content-center m-2">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center ">
                                        <img :src="require(`@/assets/umum.png`)" style="width: 90%; height: auto;" alt="">
                                    </div>
                                    <div class="flex align-items-center justify-content-center text-lg font-bold md:text-3xl">UMUM</div>
                                </div>
                            </div>

                            <div @click="pilihJenis('BPJS')" class="flex align-items-center justify-content-center m-2">
                                <div class="flex flex-column">
                                    <div class="flex align-items-center justify-content-center ">
                                        <img :src="require(`@/assets/bpjs.png`)" style="width: 90%; height: auto;" alt="">
                                    </div>
                                    <div class="flex align-items-center justify-content-center text-lg font-bold md:text-3xl">BPJS</div>
                                </div>
                            </div>
                        </div>
                    </p>
                    <!-------------------------------------------------------------------------->
                    <hr>
                    <div class="flex justify-content-center ">
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="kembali" severity="info" rounded outlined><font-awesome-icon :icon="['fas', 'circle-left']" /> Kembali</p-button>
                        </div>
                    </div>
                    <!-------------------------------------------------------------------------->
                </template>
            </p-card>
            <!--Jenis / Penjamin---------------------------------------------------------------->

            <!--Input Data---------------------------------------------------------------------->
            <p-card class="mb-5 card" v-if="posisi == 3">
                <template #title><font-awesome-icon :icon="['fas', 'address-card']" /> <span class="text-base md:text-xl">Input Data Pasien</span></template>
                <template #content>
                    <span class="text-base font-bold">Data Pasien {{jenis}} :</span>
                    <hr>
                    <p class="m-0">
                        <!--RM------------------------------------------------------------------>
                        <div class="flex flex-column gap-2">
                            <label for="no_rm">Silahkan Masukan Nomor Rekam Medis (RM) Anda :</label>
                            <img :src="require(`@/assets/kartu_pasien.png`)" class="w-full md:w-7 lg:w-6 h-auto m-auto" alt="">
                            <p-inputtext  v-model="no_rm" type="number" placeholder="klik disini untuk isi nomor RM"/>
                        </div>
                        <!--RM------------------------------------------------------------------>

                        <!--BPJS---------------------------------------------------------------->
                        <div class="flex flex-column gap-2 mt-5" v-if="jenis=='BPJS'">
                            <label for="no_bpjs">Silahkan Masukan Nomor BPJS/KIS Anda :</label>
                            <img :src="require(`@/assets/kartu_bpjs.png`)" class="w-full md:w-7 lg:w-6 h-auto m-auto" alt="">
                            <p-inputtext  v-model="no_bpjs" type="number" placeholder="klik disini untuk isi nomor BPJS/KIS"/>
                        </div>
                        <!--BPJS---------------------------------------------------------------->
                    </p>
                    <!-------------------------------------------------------------------------->
                    <hr>
                    <div class="flex justify-content-center flex-wrap">
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="backTwo" severity="info" rounded outlined><font-awesome-icon :icon="['fas', 'circle-left']" /> Kembali</p-button>
                        </div>
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="toFour" severity="success" rounded outlined>Lanjut <font-awesome-icon :icon="['fas', 'circle-right']" /></p-button>
                        </div>
                    </div>
                    <!-------------------------------------------------------------------------->
                </template>
            </p-card>
            <!--Input Data---------------------------------------------------------------------->

            <!--Poli dan Tanggal---------------------------------------------------------------->
            <p-card class="mb-5 card" v-if="posisi == 4">
                <template #title><font-awesome-icon :icon="['fas', 'calendar-check']" /> <span class="text-base md:text-xl">Poli dan Tanggal</span></template>
                <template #content>
                    <!-------------------------------------------------------------------------->
                    <span class="text-base font-bold">Data Pasien {{jenis}} :</span>
                    <hr>
                    <p class="m-0">
                        <table class="text-base font-bold">
                            <tr>
                                <td>Nomor RM</td>
                                <td>:</td>
                                <td>{{data_pasien.no_rm}}</td>
                            </tr>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{{data_pasien.nama}}</td>
                            </tr>
                            <tr v-if="jenis == 'BPJS'">
                                <td>Nomor BPJS</td>
                                <td>:</td>
                                <td>{{no_bpjs}}</td>
                            </tr>
                        </table>
                    </p>
                    <hr>
                    <br>
                    <!-------------------------------------------------------------------------->
                    <span class="text-base font-bold mt-4">Silahkan Pilih Poli dan Tanggal :</span>
                    <hr>
                    <p class="m-0">
                        <!---------------------------------------------------------------------->
                        <div class="mt-4">
                            <span class="text-base font-bold ">Silahkan Pilih Poli :</span>
                            <p-dropdown 
                                @change="changePoli"
                                v-model="poli" 
                                filter 
                                :options="data_pasien.poli" 
                                optionLabel="nama" 
                                placeholder="klik disini untuk pilih poli" class="w-full" 
                            />
                            <span v-if="errors.poli" class="text-red-500">{{errors.poli}}</span>
                        </div>
                        <!---------------------------------------------------------------------->
                        <div class="mt-6">
                            <span class="text-base font-bold ">Silahkan Pilih Tanggal Pemeriksaan :</span>
                            <select :disabled="date_range.length == 0" v-model="tanggal" class="w-full h-3rem font-bold text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary" style="appearance: auto">
                                <option selected="selected" value="">Klik Disini Untuk Pilih Tanggal</option>
                                <option v-for="(item, index) in date_range" :key="index" :value="item.tgl" :disabled="!item.status" class="font-bold text-base h-2rem">
                                    {{ item.hari }}, {{ formatDate(item.tgl) }} : {{ item.ket }}
                                </option>
                            </select>
                            <span v-if="errors.tanggal" class="text-red-500">{{errors.tanggal}}</span>
                        </div>
                        <!---------------------------------------------------------------------->

                        <!---------------------------------------------------------------------->
                        <div class="mt-6" v-if="wajib_kontrol">
                            <span class="text-base font-bold ">Silahkan Masukan Nomor Surat Kontrol anda :</span>
                            <p-inputtext v-model="kontrol" placeholder="klik disini untuk Masukan Nomor Surat Kontrol anda" class="w-full" />
                            <span v-if="errors.kontrol" class="text-red-500">{{errors.kontrol}}</span>
                        </div>
                        <!---------------------------------------------------------------------->

                        <!---------------------------------------------------------------------->
                        <div class="mt-6">
                            <span class="text-base font-bold ">Silahkan Masukan Nomor Handphone yang dapat dihubungi :</span>
                            <p-inputtext v-model="no_tlp" placeholder="klik disini untuk memasukan nomot tlp/HP" class="w-full" />
                        </div>
                        <!---------------------------------------------------------------------->
                    </p>
                    <!-------------------------------------------------------------------------->
                    <hr>
                    <div class="flex justify-content-center flex-wrap">
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="backTree" severity="info" rounded outlined><font-awesome-icon :icon="['fas', 'circle-left']" /> Kembali</p-button>
                        </div>
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="kirim" severity="success" rounded outlined>Lanjut <font-awesome-icon :icon="['fas', 'circle-right']" /></p-button>
                        </div>
                    </div>
                    <!-------------------------------------------------------------------------->
                </template>
            </p-card>
            <!--Poli dan Tanggal---------------------------------------------------------------->

            <!--Antrian Anda-------------------------------------------------------------------->
            <p-card class="mb-5 card" v-if="posisi == 5">
                <template #title><font-awesome-icon :icon="['fas', 'ticket']" /> <span class="text-base md:text-xl">Antrian Anda</span></template>
                <template #content>
                    <p class="m-0" v-if="antrian">
                        <antrian :data="antrian" />
                    </p>
                    <!-------------------------------------------------------------------------->
                    <hr>
                    <div class="flex justify-content-center flex-wrap">
                        <div class="flex align-items-center justify-content-center m-2">
                            <p-button @click="backHome" severity="info"  rounded outlined><font-awesome-icon :icon="['fas', 'home']" /> Menu Awal</p-button>
                        </div>
                    </div>
                    <!-------------------------------------------------------------------------->
                </template>
            </p-card>
            <!--Antrian Anda-------------------------------------------------------------------->


        </div>
        <!-------------------------------------------------------------------------------------->
        <!-------------------------------------------------------------------------------------->


        <!-------------------------------------------------------------------------------------->
        <div class="col-12  md:col-12 lg:col-3 xl:col-3" v-if="isIklan">
            <p-card class="mb-5 card">
                <template #content>
                    
                </template>
            </p-card>
        </div>
        <!-------------------------------------------------------------------------------------->

    </div>
    <!------------------------------------------------------------------------------------------>





    <!------------------------------------------------------------------------------------------>
    <p-confirm-dialog group="headless">
        <template #container="{ message, acceptCallback, rejectCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-info text-5xl"></i>
                </div>
                <span class="font-bold text-xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0 text-lg">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <p-button label="Tutup" icon="pi pi-times" outlined @click="rejectCallback"></p-button>
                </div>
            </div>
        </template>
    </p-confirm-dialog>
    <!------------------------------------------------------------------------------------------>
</template>