import {createStore} from 'vuex';

import BaseData from './baseData';
import uiModule from './ui';
import Token from './token';
import BedData from './bedData';
import Syarat from './syarat';

export default createStore({
    modules: {
        baseData:   BaseData,
        ui:         uiModule,
        token : Token,
        bedData : BedData,
        syarat  : Syarat
    }
});
