import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-525238df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-none align-items-center justify-content-center font-bold py-3" }
const _hoisted_2 = { class: "flex align-items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "font-bold ml-2",
  style: {"color":"black"}
}
const _hoisted_5 = { class: "hidden md:block flex-grow-1 align-items-center justify-content-center px-5 py-3" }

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_app_sub_menu = _resolveComponent("app-sub-menu")

  return (_openBlock(), _createElementBlock("div", {
    class: "layout-topbar inline-flex",
    style: _normalizeStyle({'background-color' :  _ctx.app_base_data.main_theme_color , 'color' : 'black'})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/home" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, [
              _createElementVNode("img", {
                src: _ctx.app_base_data.main_icon || require(`@/assets/logo.png`),
                class: "img-icon image-cropper"
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.app_base_data.company_name), 1)
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu_items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex inline-flex align-items-center"
        }, [
          _createVNode(_component_app_sub_menu, { menu: item }, null, 8, ["menu"])
        ]))
      }), 128))
    ])
  ], 4))
}