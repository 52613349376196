import mutations from './mutations';
import actions from './actions';
const BedData = {
    namespaced: true,
    state: {
       time        : 5, //menit
       last_update : '',
       data        : []
    },
    mutations,
   actions,
};
export default BedData;