import {Component, Vue} from 'vue-facing-decorator';
import WpPost from '@/components/WpPost/WpPost.vue';

@Component({
    name: 'home-view',
    components: {
        'wp-post' : WpPost
    }
})
export default class HomeView extends Vue{
   isIklan = false

   get app_base_data() {
    return this.$store.state.baseData;
   }

   goToLink(link: any){
        if(link){
            window.open(link, '_blank')
        }
   }
}