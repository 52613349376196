import { createApp } from 'vue'
import App from './App/App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
library.add(fas, fab);

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

//directive
//---------------------------------------------------------------
import Tooltip from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';


//---------------------------------------------------------------

//component
//---------------------------------------------------------------
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';   // optional
import Row from 'primevue/row';                   // optional
import Paginator from 'primevue/paginator';
//---------------------------------------------------------------
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Menu from 'primevue/menu';
import Sidebar from 'primevue/sidebar';
import TieredMenu from 'primevue/tieredmenu';
import Card from 'primevue/card';
import InputSwitch from 'primevue/inputswitch';
import Toast from "primevue/toast";
import Message from 'primevue/message';
import InlineMessage from 'primevue/inlinemessage';
import Image from 'primevue/image';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import ProgressBar from 'primevue/progressbar';
import Fieldset from 'primevue/fieldset';
import RadioButton from 'primevue/radiobutton';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import FileUpload from 'primevue/fileupload';
import Chip from 'primevue/chip';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import ColorPicker from 'primevue/colorpicker';
import ConfirmDialog from 'primevue/confirmdialog';
import TabMenu from 'primevue/tabmenu';
import Divider from 'primevue/divider';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ScrollPanel from 'primevue/scrollpanel';
import VirtualScroller from 'primevue/virtualscroller';

//---------------------------------------------------------------
import '@/assets/styles.scss';



//import bottomNavigationVue from "bottom-navigation-vue";
//import "bottom-navigation-vue/dist/style.css";



const app = createApp(App);
app.use(store)
app.use(router)

app.use(PrimeVue, {  ripple: false , });
app.use(ToastService);
app.use(ConfirmationService);




//directive
//---------------------------------------------------------------
app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
//---------------------------------------------------------------

//component
//---------------------------------------------------------------
app.component('font-awesome-icon', FontAwesomeIcon)
//---------------------------------------------------------------
app.component('p-data-table', DataTable);
app.component('p-column', Column);
app.component('p-column-group', ColumnGroup);
app.component('p-row', Row);
//---------------------------------------------------------------
app.component('p-avatar', Avatar);
app.component('p-inputtext', InputText);
app.component('p-button', Button);
app.component('p-dialog', Dialog);
app.component('p-badge', Badge);
app.component('p-menu', Menu);
app.component('p-tiered-menu', TieredMenu);
app.component('p-sidebar', Sidebar);
app.component('p-card', Card);
app.component('p-input-switch', InputSwitch);
app.component("p-toast", Toast);
app.component("p-message", Message);
app.component("p-inline-message", InlineMessage);
app.component("p-image",Image);
app.component("p-tag",Tag);
app.component("p-tab-view",TabView);
app.component("p-tab-panel",TabPanel);
app.component("p-panel",Panel);
app.component("p-progressbar",ProgressBar);
app.component("p-fieldset",Fieldset);
app.component("p-radio-button",RadioButton);
app.component("p-calendar",Calendar);
app.component("p-textarea",Textarea);
app.component("p-file-upload",FileUpload);
app.component("p-skeleton",Skeleton);
app.component("p-chip",Chip);
app.component("p-multiselect",MultiSelect);
app.component("p-progressspinner",ProgressSpinner);
app.component("p-paginator",Paginator);
app.component("p-dropdown",Dropdown);
app.component("p-colorpicker",ColorPicker);
app.component("p-confirm-dialog", ConfirmDialog);
app.component("p-tab-menu",TabMenu);
app.component("p-divider",Divider);
app.component("p-icon-field",IconField);
app.component("p-input-icon",InputIcon);
app.component("p-scroll-panel",ScrollPanel);
app.component("p-virtual-scroller",VirtualScroller);
//---------------------------------------------------------------




app.mount('#app')
