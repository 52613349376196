import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Main from '@/modules/Main/Main.vue'
import HomeView from '@/views/Home/HomeView.vue'
import AboutView from '@/views/About/AboutView.vue'
import CekAntrianView from '@/views/CekAntrian/CekAntrianView.vue'
import BedView from '@/views/Bed/BedView.vue'
import DaftarView from '@/views/Daftar/DaftarView.vue'

const routes: Array<RouteRecordRaw> = [
  //Main===========================================================
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '/home',
        name: 'home',
        component: HomeView
      },
      {
        path: '/cekantrian',
        name: 'cekantrian',
        component: CekAntrianView
      },
      {
        path: '/daftar',
        name: 'daftar',
        component: DaftarView
      },
      {
        path: '/bed',
        name: 'bed',
        component: BedView
      },
      {
        path: '/about',
        name: 'about',
        component: AboutView
      },
      
    ]
  }
  
  //Main===========================================================
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'font-bold',
  linkExactActiveClass: ''
})

export default router
