export default {
    toggleMenuSidebar: (context: any): any => {
        if ( context.state.layoutSidebarMode === 'overlay') {
            context.dispatch('overlayMenuActive');
        }
        if (window.innerWidth > 991) {
            context.dispatch('staticMenuDesktopInactive');
        } else {
            context.dispatch('staticMenuMobileActive');
        }   
    },

    layoutSidebarMode: (context: any): any => {
        const c = context.getters['layoutSidebarMode'];
        if ( c === 'static') {
            context.commit('layoutSidebarMode', 'overlay');
        }
        else if ( c === 'overlay') {
            context.commit('layoutSidebarMode', 'static');
        }
    },

    setLayoutSidebarMode: (context: any, newVal : any): any => {
        context.commit('layoutSidebarMode', newVal);
    },

    onChangeTheme:(context: any, newVal : any): any => {
        const elementId = 'theme-css';
        const linkElement : any = document.getElementById(elementId);
        const cloneLinkElement = linkElement.cloneNode(true);
        const oldTheme = context.getters['sourceTheme'];
        const newThemeUrl = linkElement.getAttribute('href').replace( oldTheme, newVal.theme);
        cloneLinkElement.setAttribute('id', elementId + '-clone');
        cloneLinkElement.setAttribute('href', newThemeUrl);
        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            cloneLinkElement.setAttribute('id', elementId);
            context.dispatch('darkTheme', newVal.mode === 'dark'); 
            context.dispatch('sourceTheme', newVal.theme); 
        });
        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
    },

    onChangeDarkTheme:(context: any, newData : boolean): any => {
        let theme : string = context.getters['sourceTheme'];
        const dark : string  = newData ? 'dark' : 'light';
        const oldTheme = context.getters['sourceTheme'];
        context.dispatch('darkTheme', newData); 
        if (theme.includes('light') && newData) {
            theme = theme.replace('light', 'dark');
        }else if (theme.includes('dark') && !newData) {
            theme = theme.replace('dark', 'light');
        }
        context.dispatch('sourceTheme', theme);
        const elementId = 'theme-css';
        const linkElement : any = document.getElementById(elementId);
        const cloneLinkElement = linkElement.cloneNode(true);
        const newThemeUrl = linkElement.getAttribute('href').replace( oldTheme, theme);
        cloneLinkElement.setAttribute('id', elementId + '-clone');
        cloneLinkElement.setAttribute('href', newThemeUrl);
        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            cloneLinkElement.setAttribute('id', elementId);
            context.dispatch('darkTheme', dark); 
            context.dispatch('sourceTheme', theme); 
        });
        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
    },

    overlayMenuActive: (context: any): any => {
        context.commit('overlayMenuActive');
    },
    staticMenuDesktopInactive: (context: any): any => {
        context.commit('staticMenuDesktopInactive');
    },
    staticMenuMobileActive: (context: any): any => {
        context.commit('staticMenuMobileActive');
    },
    activeMenuItem: (context: any, newVal : any): any => {
        context.commit('activeMenuItem', newVal);
    },
    
    darkTheme: (context: any, newVal : any): any => {
        context.commit('darkTheme', newVal);
    },

    sourceTheme: (context: any, newVal : any): any => {
        context.commit('sourceTheme', newVal);
    },
};