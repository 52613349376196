<div class="layout-topbar inline-flex" :style="{'background-color' :  app_base_data.main_theme_color , 'color' : 'black'}">

    <div class=" flex-none  align-items-center justify-content-center font-bold   py-3">
        <router-link to="/home">
            <div class="flex align-items-center">
                <span>
                    <img :src="app_base_data.main_icon || require(`@/assets/logo.png`)" class="img-icon image-cropper ">
                </span>
                <span class="font-bold ml-2" style="color: black;">{{app_base_data.company_name}}</span>
            </div>
        </router-link>
    </div>

    <div class="hidden md:block flex-grow-1 align-items-center justify-content-center px-5 py-3 ">
        <div v-for="(item, index) in menu_items" :key="index" class="flex inline-flex align-items-center" >
            <app-sub-menu :menu="item"></app-sub-menu>
        </div>
    </div>

   
</div>
