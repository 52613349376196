import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const uiModule = {
    namespaced: true,
    state: {
        isSidebarActive : false,
        layoutSidebarMode : 'overlay', //overlay static
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        staticMenuMobileActive: false,
        activeMenuItem: null,
        darkTheme: false,
        sourceTheme: 'lara-light-blue',
    },
    mutations,
    actions,
    getters
};

export default uiModule;