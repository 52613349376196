import {Component, Prop, Vue} from 'vue-facing-decorator';
import { ref, } from "vue";

import AntrianItem from '@/components/AntrianItem/AntrianItem.vue';

import Vue3Html2pdf from 'vue3-html2pdf'



@Component({
    name: 'comp-antrian',
    components: {
        'vue3-html2pdf' : Vue3Html2pdf,
        'antrian-item'  : AntrianItem
     }
})
export default class Antrian extends Vue{
    @Prop() data : any = ref({});

    is_loading = false

    

    simpan(){
        this.is_loading = true;
        (this.$refs['html2Pdf'] as any).generatePdf();
    }

    onPrint(){
        //
        console.log('Print....');
        const contentToPrint = this.$refs.contentToPrint as HTMLDivElement;
        const printWindow = window.open();
        if (printWindow) {
            //printWindow.document.open();
            printWindow.document.write(`
              <html>
                <head>
                    <title>Cetak</title>
                    <style rel="stylesheet">
                        :root {
                            --width: 57mm;
                            --height: 70mm;
                        }

                        .text-center {
                            text-align: center !important;
                        }

                        .font-italic {
                            font-style: italic !important;
                        }

                        .text-xs {
                            font-size: 0.75rem !important;
                        }
                          
                        .text-sm {
                            font-size: 0.875rem !important;
                        }
                          
                        .text-base {
                            font-size: 1rem !important;
                        }
                          
                        .text-lg {
                            font-size: 1.125rem !important;
                        }
                          
                        .text-xl {
                            font-size: 1.25rem !important;
                        }
                          
                        .text-2xl {
                            font-size: 1.5rem !important;
                        }
                          
                        .text-3xl {
                            font-size: 1.75rem !important;
                        }
                          
                        .text-4xl {
                            font-size: 2rem !important;
                        }
                          
                        .text-5xl {
                            font-size: 2.5rem !important;
                        }
                          
                        .text-6xl {
                            font-size: 3rem !important;
                        }
                          
                        .text-7xl {
                            font-size: 4rem !important;
                        }
                          
                        .text-8xl {
                            font-size: 6rem !important;
                        }

                        .-mt-2 {
                            margin-top: -0.5rem !important;
                        }

                        @media print{
                            @page {
                                size: var(--width) var(--height); 
                                margin: 0; 
                            }
                            body {
                                width: var(--width) ;
                                height: var(--height) ;
                                margin: 0;
                                background-color : blue;
                            }
                        }

                        .center {
                            display: flex;
                            justify-content: center;
                            width : 100%;
                            align-items: center;
                          }
                    </style>
                </head>
                <body class="center">
                    <div class="">
                    ${contentToPrint.innerHTML}
                    </div>
                </body>
              </html>
            `);
            
            printWindow.print();
            //printWindow.document.close();
            printWindow.close();
          } else {
            console.log('Gagal membuka jendela pencetakan.');
          }
    }


    hasDownloaded(){
        this.is_loading = false;
    }
   

    get app_base_data() {
        return this.$store.state.baseData;
    }
}


