import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26b2db4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-column" }
const _hoisted_2 = {
  key: 0,
  class: "flex align-items-center justify-content-center mb-1 font-bold"
}
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = {
  key: 1,
  class: "flex align-items-center justify-content-center mt-1 font-bold"
}
const _hoisted_5 = { class: "text-xs" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_router_link = _resolveComponent("router-link")
  const _directive_ripple = _resolveDirective("ripple")

  return _withDirectives((_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.menu.to || '',
    class: _normalizeClass(["align-items-center text-center pt-2",  _ctx.path == _ctx.menu.to ? ' mb-3' : 'm-1' ]),
    style: _normalizeStyle([ 
            _ctx.path == _ctx.menu.to ? 
                { 'width' : '72px' , 'height': '36px' } : {'width' : '65px' , 'height': '50px' } , 
                {'color':_ctx.app_base_data.main_theme_color} 
        ]),
    "active-class": "my-active  "
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.path == _ctx.menu.to)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("small", _hoisted_3, _toDisplayString(_ctx.menu.label), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["flex align-items-center justify-content-center",  _ctx.path == _ctx.menu.to ? 'text-2xl' : '' ])
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.menu.icon
          }, null, 8, ["icon"])
        ], 2),
        (_ctx.path != _ctx.menu.to)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("small", _hoisted_5, _toDisplayString(_ctx.menu.label), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["to", "style", "class"])), [
    [_directive_ripple]
  ])
}