
<div class="grid" >

    <!--Area Iklan---------------------------------------------------------------->
    <div class="col-12 lg:col-6 xl:col-6" v-if="isIklan">
        <p-card class="mb-0 card">
            <template #content>
                <p class="m-0">
                   
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                    quas!
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                    quas!
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque

                </p>
            </template>
        </p-card>
    </div>
     <!--Area Iklan---------------------------------------------------------------->

    <!--Main Menu------------------------------------------------------------------>
    <div :class="[isIklan ? 'col-12 md:col-12 lg:col-6 xl:col-6' : 'col-12']">
        <div class="grid align-items-center justify-content-center">
            <!--cek antrian---------------------------------------------------------------->
            <div :class="[isIklan ? 'col-12 md:col-12 lg:col-12 xl:col-6' : 'col-12 md:col-6 lg:col-6 xl:col-4']">
                <p-card class="mb-0 card p-0">
                    <template #title>
                        <span class="text-lg">Cek Antrian</span>
                    </template>
                    <template #content class="p-0">
                        <div class="grid p-0">
                            <div class="col-4 md:col-2 lg:col-3  p-0">
                                <img width="90%" :src="require(`@/assets/g-tanya.png`)" alt="">
                            </div>
                            <div class="col-8 md:col-10 lg:col-9  p-0">
                                Saya sudah pernah daftar Online tapi lupa nomer antrian atau belum disimpan,
                                Berapa nomor antrian saya? <br> <b>maka gunakan fitur cek antrian ini!!</b>
                            </div>
                            <div class="col-12 p-0 mb-0  text-center">
                                <p-button 
                                    @click="$router.push('/cekantrian')"  
                                    severity="success" 
                                     
                                    class="font-bold w-full text-center" 
                                >
                                    Cek Antrian Saya
                                </p-button>
                            </div>
                        </div>
                    </template>
                </p-card>
            </div>
            <!--cek antrian---------------------------------------------------------------->

            <!--daftar baru---------------------------------------------------------------->
            <div :class="[isIklan ? 'col-12 md:col-12 lg:col-12 xl:col-6' : 'col-12 md:col-6 lg:col-6 xl:col-4']">
                <p-card class="mb-0 card p-0">
                    <template #title>
                        <span class="text-lg">Pendaftaran Rawat Jalan</span>
                    </template>
                    <template #content class="p-0">
                        <div class="grid p-0">
                            <div class="col-4 md:col-2 lg:col-3 p-0">
                                <img width="90%" :src="require(`@/assets/g-daftar.png`)" alt="">
                            </div>
                            <div class="col-8  md:col-8 lg:col-9 p-0">
                                Saya ingin mendaftar ke layanan Rawat jalan, bagamana caranya?? <br>
                                Gunakan fitur Pendaftaran Rawat Jalan Online <b>Khusus pasien yang sudah memiliki nomor RM/nomor Paisen</b>

                            </div>
                            <div class="col-12 p-0 mb-0  text-center">
                                <p-button 
                                    @click="$router.push('/daftar')"  
                                    severity="success" 
                                     
                                    class="font-bold w-full text-center" 
                                >
                                    Daftar Sekarang
                                </p-button>
                            </div>
                        </div>
                    </template>
                </p-card>
            </div>
            <!--daftar baru---------------------------------------------------------------->

            
        </div>
    </div>
    <!--Main Menu------------------------------------------------------------------>


    <!--Berita--------------------------------------------------------------------->
    <div class="col-12">
        <wp-post title="Berita" categories="94" />
    </div>
    <!--Berita--------------------------------------------------------------------->


    <!--Artikel--------------------------------------------------------------------->
    <div class="col-12">
        <wp-post title="Artikel" categories="22" />
    </div>
    <!--Artikel--------------------------------------------------------------------->


    <!--Sosmed--------------------------------------------------------------------->
    <div class="col-12">
        <div class="grid justify-content-center ">
            <div class="col-12 xl:col-8 p-0 pl-4 pr-4">
                <span class="font-bold text-lg">Sosial Media / Website Kami</span>
                <hr>
            </div>
            <div class="col-12 p-0"></div>
            <div v-for="(item, index) in app_base_data.sosmed" :key="index" class="col-6 md:col-4 lg:col-3 xl:col-2 ">
                <p-card class="mb-0 p-0 card " :class="item.bg_color" @click="goToLink(item.url)">
                    <template #content class="">
                        <div class="grid ">
                            <div class="col-12 p-1 text-center"><font-awesome-icon :icon="item.icon" :class="item.icon_color" class="text-6xl"/></div>
                            <div class="col-12 p-1 text-center font-bold "  :class="item.text_color">{{item.title}}</div>
                            <div class="col-12 p-1 text-center font-italic" :class="item.text_color">{{item.keterangan}}</div>
                        </div>
                    </template>
                </p-card>
            </div>
        </div>
    </div>
    <!--Sosmed--------------------------------------------------------------------->
</div>