<div class="layout-wrapper " :class="containerClass">
    <!--header------------------------------------>
    <MenuBar :app_base_data="app_base_data" :menu_items="getMenus" ></MenuBar>
    <!--header------------------------------------>

    
    <!--main-------------------------------------->
    <div class="layout-main-container">
        <div class="layout-main">
            <router-view></router-view>
        </div>

        <app-bottom-menu v-if="isMobile && getMenus.length > 0" :app_base_data="app_base_data" :menu_items="getMenus" ></app-bottom-menu>
    </div>
    <!--main-------------------------------------->
    
</div>