<template>
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"  loader="bars"/>

    <p class="font-bold">
        <h5>Cek Antrian</h5>
        Cek Antrian adalah fitur yang digunakan mengecek Antrian anda yang sudah mendaftar 
        namun anda lupa dengan nomor antrian atau lupa menyimpan antrian anda
    </p>

    <p-message  severity="info" :closable="false">Khusus Pasien Yang sudah mendaftar Online</p-message>

    <!------------------------------------------------------------------------------------------>
    <div class="grid align-items-center justify-content-center">
        <!-------------------------------------------------------------------------------------->
        <div class="col-12  md:col-4 lg:col-4 xl:col-4" v-if="isIklan">
            <p-card class="mb-0 card">
                <template #content>
                    <p class="m-0">
                       
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                    </p>
                </template>
            </p-card>
        </div>
        <!-------------------------------------------------------------------------------------->

        <!-------------------------------------------------------------------------------------->
        <div class="col-12  md:col-4 lg:col-4 xl:col-4">
            <p-card class="mb-0 card">
                <template #title><font-awesome-icon :icon="['fas', 'ticket']" /> Cek Nomor Antrian Anda</template>
                <template #content>
                    <p class="m-0">
                        <p-message v-if="error" severity="error">{{error}}</p-message>
                        <p-message v-if="success" severity="success">{{success}}</p-message>

                        <form @submit.prevent="submit" v-if="showForm">
                            <div class="field grid">
                                <label for="no_rm" class="col-12 mb-2 md:col-4 md:mb-0">Nomor RM *</label>
                                <div class="col-12 md:col-8">
                                    <span>Silahkan Masukan nomor RM atau nomor Pasien anda!! <font-awesome-icon :icon="['fas', 'hand-point-down']" /></span>
                                    <p-inputtext :class="{'p-invalid' : errors?.no_rm}" v-model="no_rm" id="no_rm" type="number"
                                        class="w-full border-solid border-round" size="small"  />
                                    <span class="text-red-600" v-for="(item, index) in errors?.no_rm" :key="index">{{ item }}</span>
                                </div>
                            </div>
                        
                        
                            <div class="field grid">
                                <label for="tgl_periksa" class="col-12 mb-2 md:col-4 md:mb-0">Tgl Periksa *</label>
                                <div class="col-12 md:col-8">
                                    <span>Silahkan Masukan Tanggal Periksa yang pernah anda daftarkan!! <font-awesome-icon :icon="['fas', 'hand-point-down']" /></span>
                                    <p-inputtext :class="{'p-invalid' : errors?.tgl_periksa}" v-model="tgl_periksa" id="tgl_periksa" type="date"
                                        class="w-full border-solid border-round" size="small" />
                                    <span class="text-red-600" v-for="(item, index) in errors?.tgl_periksa" :key="index">{{ item }}</span>
                                </div>
                            </div>
                        
                            <p-button @click="submit" :icon="isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-search'" :disabled="isLoading"
                                label="Cari" class="w-full" severity="success" size="small" outlined rounded />
                        </form>

                        <div v-else>
                            <div class="text-center">
                                <h6><b>Berikut adalah Nomor Antrian Anda</b></h6>
                            </div>
                            <section v-for="(item, index) in data" :key="index" class="mb-4">
                                <antrian :data="item" />
                            </section>
                            
                            
                            <p-button @click="onShowForm" icon='pi pi-search' 
                                label="Pencarian Baru" class="w-full" severity="success" size="small" outlined rounded />
                        </div>
                    </p>
                </template>
            </p-card>
        </div>
        <!-------------------------------------------------------------------------------------->

        <!-------------------------------------------------------------------------------------->
        <div class="col-12  md:col-4 lg:col-4 xl:col-4" v-if="isIklan">
            <p-card class="mb-0 card">
                <template #content>
                    <p class="m-0">
                       
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                        quas!
                        Lorem ipsum dolor sit amet, consectetfdur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque
                    </p>
                </template>
            </p-card>
        </div>
        <!-------------------------------------------------------------------------------------->

    </div>
    <!------------------------------------------------------------------------------------------>


</template>