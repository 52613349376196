import {Component, Vue, Prop} from 'vue-facing-decorator';
import { ref } from "vue";

import SubMenu      from '@/components/HeaderMenu/SubMenu/SubMenu.vue';


@Component({
    name : 'app-header-menu',
    components: {
        'app-sub-menu'      : SubMenu,
    }
})
export default class HeaderMenu extends Vue {
    @Prop() app_base_data = ref([]);
    @Prop() menu_items = ref([]);

    toggleAppSide() {
        this.$store.dispatch('ui/toggleMenuSidebar'); 
    }


}