import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex flex-wrap align-items-center justify-content-between gap-3" }
const _hoisted_3 = { class: "mt-5" }

export function render(_ctx, _cache) {
  const _component_antrian_item = _resolveComponent("antrian-item")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_panel = _resolveComponent("p-panel")
  const _component_vue3_html2pdf = _resolveComponent("vue3-html2pdf")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_p_panel, {
      header: 'tgl : ' + _ctx.data.tgl_pemeriksaan +' | jam : '+ _ctx.data.jam 
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_p_button, {
            text: "",
            onClick: _ctx.onPrint
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: ['fas', 'print'] }),
              _createTextVNode(" Print")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            label: "Simpan",
            outlined: "",
            severity: "success",
            rounded: "",
            onClick: _ctx.simpan,
            icon: _ctx.is_loading ? 'pi pi-spin pi-spinner' : 'pi pi-download',
            disabled: _ctx.is_loading
          }, null, 8, ["onClick", "icon", "disabled"])
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("section", {
          class: "m-0 area-print",
          id: 'area-'+_ctx.data.antrian_poli,
          ref: "contentToPrint"
        }, [
          _createVNode(_component_antrian_item, { data: _ctx.data }, null, 8, ["data"])
        ], 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["header"]),
    _createVNode(_component_vue3_html2pdf, {
      "show-layout": false,
      "float-layout": true,
      "enable-download": true,
      "preview-modal": false,
      "paginate-elements-by-height": 1400,
      filename: 'Antrian-' + _ctx.data.no_antri + '-' + _ctx.data.tgl_boking,
      "pdf-quality": 2,
      "manual-pagination": false,
      "pdf-format": "a7",
      "pdf-orientation": "portrait",
      "pdf-content-width": "74mm",
      onHasDownloaded: _cache[0] || (_cache[0] = $event => (_ctx.hasDownloaded($event))),
      ref: "html2Pdf"
    }, {
      "pdf-content": _withCtx(() => [
        _createElementVNode("p", _hoisted_3, [
          _createVNode(_component_antrian_item, { data: _ctx.data }, null, 8, ["data"])
        ])
      ]),
      _: 1
    }, 8, ["filename"])
  ], 64))
}