import {Component, Prop, Vue} from 'vue-facing-decorator';
import { ref } from "vue";
import SubMenu from '@/components/BottomMenu/SubMenu/SubMenu.vue';
@Component({
    name: 'app-bottom-menu',
    components: {
        'app-sub-menu'      : SubMenu,
    }
})
export default class BottomMenu extends Vue{
    @Prop() app_base_data = ref([]);
    @Prop() menu_items = ref([]);
}