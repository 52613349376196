import {Component, Vue} from 'vue-facing-decorator';
import Loading from 'vue-loading-overlay';
import ax from '@/utils/axios';
import { ref } from "vue";


@Component({
    name: 'bed-view',
    components: {       
        'loading'    : Loading,
    }
})
export default class BedView extends Vue{
    isLoading = false;
    dataBad   = ref([]);
    errorMsg  = "";
    infoMsg   = "";
    waktu   :any  = 1
    afterUpdate = false

   
    mounted(){
        this.waktu = this.$store.state.bedData.time;
        this.infoMsg = "Data yang kami tampilkan dapat berubah-ubah seisingnya waktu!! data dapat diperbahasui setiap " +this.waktu+" menit sekali"
        const l_data : any=localStorage.getItem('data_bed');
        const last_update : any=localStorage.getItem('last_update_data_bed');

        const now = Date.now() 
        const selisih = (now - last_update) / 60000 ;

        if(l_data && selisih < this.waktu){
            this.dataBad = JSON.parse(l_data);
        }else{
            this.getData();
        }
        
    }

    refresh(){
        const last_update : any=localStorage.getItem('last_update_data_bed');
        const now = Date.now() 
        const selisih = (now - last_update) / 60000 ;
        if(selisih >= this.waktu){
            this.getData();
        }else{
            this.$toast.add({ severity: 'warn',  summary: 'Update Data', detail: "data belum dapat diUpdate!!", life: 3000 });
        }
    }

    async getData(){
        this.isLoading = true;
        this.errorMsg  = "";

        await ax.post('get/bed', {
            user: this.$store.state.token.user,
            key : this.$store.state.token.key
        }).then(response => {
            this.isLoading = false;
            if(response.data.status){
                localStorage.setItem('last_update_data_bed', JSON.stringify(Date.now()));
                localStorage.setItem('data_bed', JSON.stringify(response.data.data));
                const l_data : any=localStorage.getItem('data_bed');
                this.dataBad = JSON.parse(l_data);
                this.afterUpdate = true
                this.$toast.add({ severity: 'success',  summary: 'Data Didapat', detail: response.data.msg, life: 3000 });
            }else{
                this.$toast.add({ severity: 'warn',  summary: 'Data Kosong', detail: response.data.msg, life: 3000 });
                this.errorMsg  = response.data.msg;
            }
        })
        .catch(error => {
            this.isLoading = false;
            this.$toast.add({ severity: 'error',  summary: 'Error', detail: error.message, life: 3000 });
            this.errorMsg  =  error.message;
        });
    }
}