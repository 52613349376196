import {Component, Prop, Vue} from 'vue-facing-decorator';
import { ref, } from "vue";

@Component({
    name: 'comp-antrian-item',
})
export default class AntrianItem extends Vue{
    @Prop() data : any = ref({});

    get app_base_data() {
        return this.$store.state.baseData;
    }

    formatDate(dateString: string): string {
        try {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${(day < 10 ? '0' : '') + day}-${(month < 10 ? '0' : '') + month}-${year}`;
        }catch (error : any) {
            console.error('Error formatting date:', error.message);
            return dateString;
          }
      }
}