import {Component, Vue} from 'vue-facing-decorator';

import HeaderMenu from '@/components/HeaderMenu/HeaderMenu.vue';


//import  { CurvedBottomNavigation  }  from "bottom-navigation-vue";
//import "bottom-navigation-vue/dist/style.css";

import BottomMenu from '@/components/BottomMenu/BottomMenu.vue';

@Component({
    name: 'main-root',
    components: {
        'MenuBar' : HeaderMenu,
        'app-bottom-menu' :BottomMenu // CurvedBottomNavigation
    }
})
export default class Main extends Vue{
   
    isMobile = window.innerWidth <= 768;

    selected = 1;
    options = [
        { id: 1, icon: "fas fa-wallet", title: "Wallet" },
        { id: 2, icon: "fas fa-wallet", title: "Wallet" },
    ];

    get app_base_data() {
        return this.$store.state.baseData;
    }

    get getMenus() {
        return [
            {   
                id:1,
                label: 'Home',
                icon: ['fas', 'house'],
                to : '/home',
            },
            {
                id:2,
                label: 'Cek Antrian',
                icon: ['fas', 'ticket'],
                to : '/cekantrian',
            },
            { 
                id:3,
                label: 'Daftar',
                icon: ['fas', 'circle-plus'],
                to : '/daftar',
            },
            {
                id:4,
                label: 'BED',
                icon: ['fas', 'bed'],
                to : '/bed',
            },
            {
                id:5,
                label: 'Tentang',
                icon: ['fas', 'circle-info'],
                to : '/about',
            },
        ];
    }

    get containerClass(){
          return {
            'layout-overlay': this.$store.getters['ui/layoutSidebarMode'] === 'overlay',
            'layout-static' : this.$store.getters['ui/layoutSidebarMode'] === 'static',
            'layout-static-inactive': this.$store.getters['ui/staticMenuDesktopInactive'] && this.$store.getters['ui/layoutSidebarMode'] === 'static',
            'layout-overlay-active': this.$store.getters['ui/overlayMenuActive'],
            'layout-mobile-active': this.$store.getters['ui/staticMenuMobileActive'],
          };
    }
    
    
    /* eslint-disable */
    isOutsideClicked(event:any){
        const sidebarEl = document.querySelector('.layout-sidebar');
        const topbarEl = document.querySelector('.layout-menu-button');
        const c = !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
        if (c) {
            if(this.$store.getters['ui/isSidebarActive']){
                this.$store.dispatch('ui/toggleMenuSidebar'); 
            }
        }
    }

    handleResize() {
        this.isMobile = window.innerWidth <= 768;
    }

    created() {
        window.addEventListener('resize', this.handleResize);
    }
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    }
    mounted() {
        document.addEventListener('click', this.isOutsideClicked);
    }

    beforeUnmount() {
        document.removeEventListener('click', this.isOutsideClicked);
    }
    //========================================================================================
}