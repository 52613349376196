import {Component, Vue} from 'vue-facing-decorator';

@Component({
    name: 'about-view',
})
export default class AboutView extends Vue{
    get app_base_data() {
        return this.$store.state.baseData;
    }

}