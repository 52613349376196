<template>
<!----------------------------------------------------------------->
<div class="grid">

    <!------------------------------------------------------------->
    <div class="col-12 lg:col-12 xl:col-12 ">
        <p-card class="mb-0 card">
            <template #title>
                <div class="flex flex-column">
                    <div class="flex align-items-center justify-content-center text-center">
                        <img :src="app_base_data.main_icon || require(`@/assets/logo.png`)"
                            class="img-icon image-cropper ">
                    </div>
                    <div class="flex align-items-center justify-content-center  font-bold m-2 text-center">
                        {{app_base_data.company_name}}
                    </div>
                </div>
            </template>
            <template #content>
                <!--moto-------------------------------------------------------->
                <p v-if="app_base_data.moto" class="text-center"><b><i>"{{app_base_data.moto}}"</i></b></p>

                <!--tentang----------------------------------------------------->
                <p class="" v-if="app_base_data.description">
                    <b class="text-left">Tentang :</b><br>
                <div class="text-justify">
                    {{app_base_data.description}}
                </div>
                </p>
            </template>
        </p-card>
    </div>
    <!------------------------------------------------------------->

</div>
<!----------------------------------------------------------------->


<!----------------------------------------------------------------->
<div class="grid">
    <!--kanan------------------------------------------------------>
    <div class="col-12 lg:col-6 xl:col-6 p-0" >
        <!--visi--------------------------------------------------->
        <div class="col-12 lg:col-12 xl:col-12" v-if="app_base_data.visi">
            <p-card class="mb-0 card">
                <template #title> <small>Visi</small> </template>
                <template #content>
                    <p class="">
                    <div v-for="(item, index) in app_base_data.visi" :key="index" class="text-justify">
                        {{item}}
                        <hr v-if="app_base_data.visi.length > 1">
                    </div>
                    </p>
                </template>
            </p-card>
        </div>
        <!--visi--------------------------------------------------->

        <!--misi--------------------------------------------------->
        <div class="col-12 lg:col-12 xl:col-12" v-if="app_base_data.misi">
            <p-card class="mb-0 card">
                <template #title> <small>Misi</small> </template>
                <template #content>
                    <p class="">
                    <div v-for="(item, index) in app_base_data.misi" :key="index" class="text-justify">
                        {{item}}
                        <hr v-if="app_base_data.misi.length > 1">
                    </div>
                    </p>
                </template>
            </p-card>
        </div>
         <!--misi-------------------------------------------------->
    </div>
    <!--kanan------------------------------------------------------>

    <!--kiri------------------------------------------------------->
    <div class="col-12 lg:col-6 xl:col-6 p-0">
        <!--kontak------------------------------------------------->
        <div class="col-12 lg:col-12 xl:col-12">
            <p-card class="mb-0 card">
                <template #title> <small>Kontak</small> </template>
                <template #content>
                    <table class="text-justify">
                        <tr valign="top">
                            <td>Alamat</td>
                            <td>:</td>
                            <td>{{app_base_data.address}}</td>
                        </tr>
                        <tr valign="top">
                            <td>Telepon</td>
                            <td>:</td>
                            <td>{{app_base_data.phone}}</td>
                        </tr>
                        <tr valign="top">
                            <td>FAX</td>
                            <td>:</td>
                            <td>{{app_base_data.fax}}</td>
                        </tr>
                        <tr valign="top">
                            <td>Email</td>
                            <td>:</td>
                            <td>{{app_base_data.email}}</td>
                        </tr>
                    </table>
                </template>
            </p-card>
        </div>
        <!--kontak------------------------------------------------->

        <!--About app---------------------------------------------->
        <div class="col-12 lg:col-12 xl:col-12">
            <p-card class="mb-0 card">
                <template #title> <small>About App</small> </template>
                <template #content>
                    Aplikasi Pendaftaran Rawat Jawal Online (Versi : {{app_base_data.version}})
                </template>
            </p-card>
        </div>
        <!--About app---------------------------------------------->

    </div>
    <!--kiri------------------------------------------------------->

</div>
<!----------------------------------------------------------------->
</template>