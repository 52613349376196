export default {
    layoutSidebarMode: (state: any): string => state.layoutSidebarMode,
    overlayMenuActive: (state: any): boolean => state.overlayMenuActive,
    staticMenuDesktopInactive: (state: any): boolean => state.staticMenuDesktopInactive,
    staticMenuMobileActive: (state: any): boolean => state.staticMenuMobileActive,
    isSidebarActive: (state: any): boolean => state.overlayMenuActive || state.staticMenuMobileActive,
    activeMenuItem: (state: any): any => state.activeMenuItem,

    darkTheme: (state: any): any => state.darkTheme,
    sourceTheme: (state: any): any => state.sourceTheme,

    
};