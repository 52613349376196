import {Component, Vue} from 'vue-facing-decorator';
import { ref } from "vue";
import Loading from 'vue-loading-overlay';
import ax from '@/utils/axios';

import Antrian from '@/components/Antrian/Antrian.vue';

@Component({
    name: 'cek-antrian-view',
    components: {       
        'loading'    : Loading,
        'antrian'    : Antrian
    }
})
export default class CekAntrianView extends Vue{
    isLoading = false;
    isIklan = false;

    showForm  = true
    
    errors : any= ref([])
    success:any = false
    error : any = false

    data : any= ref([])

    no_rm         : any = ref('');
    tgl_periksa   : any = ref('');

    mounted(){
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.tgl_periksa = `${year}-${month}-${day}`;
    }

    onShowForm(){
        this.no_rm  = '';
        this.data  = [];
        this.showForm =true;
    }

    async submit(){
        this.data       = []
        this.success    = false
        this.error      = false
        this.errors     = ref([{}])
        const formData  = new FormData();
        const url       = 'cekantrian';

        if(!this.no_rm){
            this.errors.no_rm = ['Nomor RM Wajib Diisi!! Silahkan Isi terlebih dahulu'];
            this.$toast.add({ severity: 'warn',  summary: 'Input', detail: "Nomor RM Wajib Diisi!! Silahkan Isi terlebih dahulu", life: 3000 });
        }
        if(!this.tgl_periksa){
            this.errors.tgl_periksa = ['Tanggal Periksa Wajib Diisi!! Silahkan Isi terlebih dahulu']
            this.$toast.add({ severity: 'warn',  summary: 'Input', detail: "Tanggal Periksa Wajib Diisi!! Silahkan Isi terlebih dahulu", life: 3000 });
        }
        if(this.no_rm && this.tgl_periksa){
            this.isLoading  = true;
            formData.append('user', this.$store.state.token.user);
            formData.append('key', this.$store.state.token.key);
            formData.append('no_rm', this.no_rm);
            formData.append('tgl', this.tgl_periksa);
            
            await ax.post(url, formData).then((res : any ) => {
                this.isLoading = false;
                if(res.data.status){
                    this.$toast.add({ severity: 'success',  summary: 'Cek Antrian', detail: res.data.msg, life: 3000 });
                    this.data = res.data.data;
                   // this.success = res.data.msg;
                    this.showForm  = false;
                }else{
                    this.$toast.add({ severity: 'warn',  summary: 'Cek Antrian', detail: res.data.msg, life: 3000 });
                    this.error = res.data.msg;
                }
            }).catch((err : any) => {
                this.isLoading = false;
                this.$toast.add({ severity: 'error',  summary: 'Cek Antrian', detail: err.message, life: 3000 });
                this.error = err.message;
            })
        }else{
            this.error = "Terdapat Kesalahan Input!!! Silahkan Isi terlebih dahulu"
            
        }

        console.log(this.data)
    }
}