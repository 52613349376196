import axios from 'axios';
//import store from '../store';

const intance = axios.create({
    baseURL: 'https://api.soeroto.my.id/',
    headers: {
        "Access-Control-Allow-Origin" : "*",
        "Access-Control-Allow-Headers" : "*",
        "Access-Control-Allow-Methods" : "*",
        "Content-type": "application/json",
        'Accept' : 'application/json',
    }
    
});


intance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export default intance;
