
export default {
    //toggleMenuSidebar: (state: any): void => {
    //    state.menuSidebarCollapsed = !state.menuSidebarCollapsed;
    //},
    overlayMenuActive: (state: any): void => {
        state.overlayMenuActive = !state.overlayMenuActive;
    },
    staticMenuDesktopInactive: (state: any): void => {
        state.staticMenuDesktopInactive = !state.staticMenuDesktopInactive;
    },
    staticMenuMobileActive: (state: any): void => {
        state.staticMenuMobileActive = !state.staticMenuMobileActive;
    },
    activeMenuItem: (state: any, newData: any): void => {
        state.activeMenuItem = newData;
    },

    darkTheme: (state: any, newData: any): void => {
        state.darkTheme = newData;
    },
    sourceTheme: (state: any, newData: any): void => {
        state.sourceTheme = newData;
    },

    layoutSidebarMode: (state: any, newData: any): void => {
        state.layoutSidebarMode = newData;
    },
};
