import { Component, Vue, Prop } from "vue-facing-decorator";
import { ref } from "vue";
import wp from  '@/utils/axios_wp';

@Component({
  name: "comp-wp-post",
})
export default class WpPost extends Vue {
  @Prop title:any = ref('')
  @Prop per_page:any = ref('')
  @Prop({ default: true }) paginator:any = ref('')
  @Prop tags : any = ref('')
  @Prop categories : any = ref('')

  isLoading  = false;
  errorMsg   = '';
  posts : any= ref([]);
  page = 1;
  max_page = false
  

  mounted(){
    this.getPost();
  }

  previous_page(){
    this.max_page =false;
    
    if(this.page == 1){
      this.page = 1
    }else{
      this.page -= 1;
    }
    this.getPost();
  }

  next_page(){
    this.page += 1;
    this.getPost();
  }

  refresh(){
    this.page = 1
    this.getPost();
  }

  async getPost(){
    const tmp : any = this.posts;
    this.isLoading = true;
    this.errorMsg  = '';
    this.posts = ref([])
    await wp.get('/', {
      params:{
        page:this.page,
        ...(this.per_page && { per_page: this.per_page }),
        ...(this.tags && { tags: this.tags }),
        ...(this.categories && { categories: this.categories }),
      }
    })
    .then(response => {
      this.isLoading = false;
      this.posts = response.data;
      console.log(response)
    })
    .catch(error => {
      this.isLoading = false;
     
      if(error.response.status == '400'){
        this.$toast.add({ severity: 'error',  summary: 'Error', detail: error.response.data.message, life: 5000 });
        this.max_page = true;
        this.posts = tmp;
        this.page -= 1;
      }else{
        this.$toast.add({ severity: 'error',  summary: 'Error', detail: error.message, life: 5000 });
        this.errorMsg  =  error.message;
      }
      console.log(error.response.data)
      
    });
  }
  


  truncateContent(content: any) {
    // Memisahkan kata-kata dalam konten
    const words = content.split(" ");
    // Mengambil 100 kata pertama
    let truncatedContent = words.slice(0, 20).join(" ");
    // Menambahkan elipsis (...) jika konten lebih dari 100 kata
    if (words.length > 20) {
      truncatedContent += "...";
    }
    return truncatedContent;
  }

  goToLink(link: any) {
    if (link) {
      window.open(link, "_blank");
    }
  }
}
