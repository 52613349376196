<template>
<loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"  loader="bars"/>

<p class="font-bold">
    <h5>Ketersediaan BED</h5>
    Berikut adalah data yang menampilkan ketersediaan bed pada bangsal-bangsal kami
</p>

<p-button  @click="refresh" icon="pi pi-replay" severity="info" outlined label="Perbaharui Data" />

<p-message v-if="dataBad.length " severity="info" :closable="false">{{infoMsg}}</p-message>
<div class="grid" v-if="dataBad.length">
    <div v-for="(item, index) in dataBad" :key="index" class="col-12 md:col-6 lg:col-4 xl:col-3">
        <p-card class="mb-0 card">
            <template #title><font-awesome-icon :icon="['fas', 'hospital']" /> {{item.nama_bangsal}} </template>
            <template #content>
                <div class="grid text-teal-50">
                    <div class="col-6">
                        <div class="text-center p-1 border-round-xl bg-red-400 font-bold ">
                            <font-awesome-icon :icon="['fas', 'bed-pulse']" /> TERISI
                            <br>
                            <span class="text-3xl">{{item.total_terisi}}</span> 
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="text-center p-1 border-round-xl bg-green-600 font-bold ">
                            <font-awesome-icon :icon="['fas', 'bed']" /> TERSEDIA 
                            <br>
                            <span class="text-3xl">{{item.total_sisa}}</span> 
                        </div>
                    </div>
                </div>

                <p class="m-0 p-0" v-if="item.ruangan">
                    <p-data-table :value="item.ruangan" tableStyle="width:100%">
                        <p-column sortable field="nama" header="KELAS" ></p-column>
                        <p-column sortable field="terisi" header="TERISI" class="text-center font-bold text-red-700"   style="width: 25%"></p-column>
                        <p-column sortable field="sisa" header="TERSEDIA" class="text-center font-bold text-green-700" style="width: 25%"></p-column>
                    </p-data-table>
                </p>
            </template>
            <template #footer>
                
            </template>
        </p-card>
    </div>
</div>
<p-message class="mb-8" v-if="dataBad.length " severity="info" :closable="false">{{infoMsg}}</p-message>

<p-message v-if="errorMsg" severity="error" :closable="false">{{errorMsg}}</p-message>
</template>

