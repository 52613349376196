import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e93f99fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }

export function render(_ctx, _cache) {
  const _component_app_sub_menu = _resolveComponent("app-sub-menu")

  return (_openBlock(), _createElementBlock("div", {
    class: "layout-bottom-bar justify-content-around align-items-center",
    style: _normalizeStyle([{"height":"50px"}, {'background-color' : _ctx.app_base_data.second_theme_color}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu_items, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex align-items-center justify-content-center"
        }, [
          _createVNode(_component_app_sub_menu, {
            app_base_data: _ctx.app_base_data,
            menu: item
          }, null, 8, ["app_base_data", "menu"])
        ]))
      }), 128))
    ])
  ], 4))
}