import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_loading = _resolveComponent("loading")
  const _component_p_toast = _resolveComponent("p-toast")
  const _component_p_confirm_dialog = _resolveComponent("p-confirm-dialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isAppLoading)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_loading, {
      active: _ctx.isAppLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.isAppLoading) = $event)),
      "can-cancel": false,
      "is-full-page": true,
      loader: "bars"
    }, null, 8, ["active"]),
    _createVNode(_component_p_toast),
    _createVNode(_component_p_confirm_dialog)
  ], 64))
}