import axios from 'axios';
//import store from '../store';

const intance = axios.create({
    baseURL: 'https://rssoeroto.ngawikab.go.id/wp-json/wp/v2/posts',
    headers: {
        "Content-type": "application/json",
        'Accept' : 'application/json',
    }
    
});


intance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export default intance;
