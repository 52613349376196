import mutations from './mutations';

const BaseData = {
    namespaced: true,
    state: {
        app_name: 'Pendaftaran Online',
        company_name: 'RSUD Dr. Soeroto Ngawi',
        moto : 'Kesembuhan Dan Kepuasan Anda Adalah Kebahagiaan Kami',
        visi : [
            'Menjadi Rumah Sakit yang ramah, bermutu, terpercaya dan mampu mewujudkan fungsinya sebagai pusat pelayanan, pendidikan, dan penelitian'
        ],
        misi : [
            'Mewujudkan kinerja petugas Rumah Sakit yang ramah, santun dan memberi kemudahan dalam proses pelayanan;',
            'Mewujudkan penyelenggaraan kesehatan yang Bermutu, Memuaskan dan Terjangkau semua lapisan masyarakat dengan Sumber Daya Manusia yang Profesional;',
            'Menjamin ketersediaan sarana dan prasarana rumah sakit yang memadai;',
            'Mewujudkan pelayanan administrasi manajemen yang profesional;',
            'Mewujudkan Rumah Sakit yang indah, tertib dan aman dalam rangka menciptakan Rumah Sakit yang berwawasan lingkungan;',
            'Meningkatkan dan menghasilkan sumber daya manusia yang kompeten, terampil dan profesional dalam pelayanan kesehatan melalui pendidikan dan penelitian.;'
            
        ],
        address: 'Jl. Dr. Wahidin No. 27, Ngawi, Jawa Timur',
        description: 'RSUD Dr. Soeroto Ngawi keberadaanya telah dimulai sejak zaman penjajahan Belanda sekitar tahun 1888 sebagai rumah sakit yang ditempati oleh dokter militer Belanda, yang pada zamannya diyakini sebagai bagian komplek benteng Van Den Bosch. Kala itu Ngawi disebut sebagai kota garnisun (Breitenstein, H. 1900. 21 Jahre in Indien. (L. Fernan)). Terletak di tepi selatan sungai bengawan Solo tepatnya di  Jl. Dr. Wahidin no. 27 Kabupaten Ngawi. Nama rumah sakit ini diangkat dari salah satu dokter kepala pada masa kemerdekaan Republik Indonesia yaitu  Dr.Soeroto.',
        email: 'rssoeroto@ngawikab.go.id',
        phone: '0351-749023',
        fax: '0351-744774',

        sosmed : [
            {
                'icon'  : ['fab', 'chrome'],
                'title' : 'Website',
                'url'   : 'https://rssoeroto.ngawikab.go.id/',
                'keterangan' : '',
                'bg_color' : 'bg-yellow-100',
                'icon_color' : 'text-green-800',
                'text_color' : 'text-green-800',
            },
            {
                'icon'  : ['fab', 'instagram'],
                'title' : 'Instagram',
                'url'   : 'https://www.instagram.com/rsud.drsoeroto/',
                'keterangan' : '',
                'bg_color' : 'bg-purple-200',
                'icon_color' : 'text-pink-600',
                'text_color' : '',
            },
            {
                'icon'  : ['fab', 'facebook'],
                'title' : 'Facebook',
                'url'   : 'https://www.facebook.com/rsuddrsoeroto.ngawi',
                'keterangan' : '',
                'bg_color' : 'bg-indigo-200',
                'icon_color' : 'text-green-800',
                'text_color' : '',
            },
            {
                'icon'  : ['fab', 'youtube'],
                'title' : 'Youtube',
                'url'   : 'https://www.youtube.com/@rsuddr.soerotoakreditasing2848',
                'keterangan' : '',
                'bg_color' : 'bg-green-200',
                'icon_color' : 'text-red-700',
                'text_color' : '',
            },
            
        ],
        main_icon: '',
        second_icon: 'https://i0.wp.com/rssoeroto.ngawikab.go.id/wp-content/uploads/2018/04/logo-rsu.png?resize=255%2C255&ssl=1',
        img_background: '',
        main_theme_color: '#05940C',
        second_theme_color: '#FDF036',
        version: '2.0.0',
    },
    mutations
};

export default BaseData;
