<div v-if="menu.items" @click="toggle" aria-haspopup="true" aria-controls="overlay_tmenu">
    <font-awesome-icon :icon="menu.icon" />
    <span class="ml-2">{{ menu.label }}</span>
    <i class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1 pi pi-angle-down"></i>

    <p-tiered-menu ref="menu" id="overlay_tmenu" :model="menu.items" popup >
        <template #item="{ item, props, hasSubmenu , root}">
            <router-link :to="item.to || ''" v-ripple class="flex align-items-center" v-bind="props.action" >
                <font-awesome-icon :icon="item.icon" />
                <span class="ml-2">{{ item.label }} <p-badge v-if="item.badge" class="ml-auto" :value="item.badge" /></span>
                
                <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
                <i v-if="hasSubmenu" :class="['pi pi-angle-down text-primary', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
            </router-link>
        </template>
    </p-tiered-menu>
</div>

<router-link v-else :to="menu.to || ''" v-ripple class="flex align-items-center mr-3" style="color: black;" >
    <font-awesome-icon :icon="menu.icon" />
    <span class="ml-2">{{ menu.label }} <p-badge v-if="menu.badge" class="ml-auto " :value="menu.badge" /></span>
</router-link>