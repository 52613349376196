import {Component, Vue} from 'vue-facing-decorator';
import { ref } from "vue";
import Loading from 'vue-loading-overlay';
import Antrian from '@/components/Antrian/Antrian.vue';

import ax from '@/utils/axios';

import { useConfirm } from "primevue/useconfirm";

@Component({
    name: 'daftar-view',
    components: {       
        'loading'    : Loading,
        'antrian'    : Antrian
    }
})
export default class DaftarView extends Vue{
    confirm = useConfirm();

    //get app_base_data() {
    //    return this.$store.state.baseData;
    //}

    isLoading = false;
    isLodingSyarat = false;
    isIklan = false;

    

    errors : any= ref({})
    success:any = false
    error : any = false
    //-------------------------------------------------------------
    posisi = 1;
    syarat : any = ref([])
    //-------------------------------------------------------------
    data_pasien : any = ref({})
    date_range : any = ref([])

    jenis   : any       = ''
    wajib_kontrol:any   = false
    no_rm   : any       = ref('')
    no_bpjs : any       = ref('')
    poli : any          = ref()
    tanggal: any        = ''
    no_tlp: any         = ''
    kontrol: any        = ''
    antrian:any         = ref()



    //-------------------------------------------------------------
    mounted(){
        this.getSyarat();
    }
    //-------------------------------------------------------------
    pilihJenis(jenis :any){
        this.jenis = jenis;
        this.lanjut();
    }
    //-------------------------------------------------------------
    changePoli(event : any){
        console.log(event.value)
        if(event.value){
            this.getDate(event.value.kode_poli);
        }
    }

    changeDate(event : any){
        console.log(event.value)
        console.log(this.tanggal)
    }
    //-------------------------------------------------------------
    toFour(){
        if(!this.no_rm){
            this.$toast.add({ severity: 'warn',  summary: 'Data Pasien', detail: "Nomor RM Wajib Diisi!! Silahkan Isi terlebih dahulu", life: 4000 });
        }

        if(this.jenis == 'UMUM'){
            if(this.no_rm){
                this.getRM();
            }
        }else if(this.jenis == 'BPJS'){
            if(!this.no_bpjs){
                this.$toast.add({ severity: 'warn',  summary: 'Data Pasien', detail: "Nomor BPJS/KIS Wajib Diisi!! Silahkan Isi terlebih dahulu", life: 4000 });
            }else if(this.no_bpjs.length != 13){
                this.$toast.add({ severity: 'warn',  summary: 'Data Pasien', detail: "Nomor BPJS/KIS harus 13 digit!! Silahkan Isi dengan benar", life: 4000 });
            }

            if(this.no_rm && this.no_bpjs.length == 13){
                this.getRM();
            }
        }
    }
    //-------------------------------------------------------------
    backTwo(){
        this.no_rm = '';
        this.no_bpjs = '';
        this.kembali();
    }
    backTree(){
        this.poli           = {};
        this.tanggal        = "";
        this.date_range     = ref([]);
        this.data_pasien    = ref();
        this.no_tlp         = "";
        this.wajib_kontrol  = false;
        this.kontrol        = "";
        this.kembali();
    }
    backHome(){
        this.no_rm          = '';
        this.no_bpjs        = '';
        this.poli           = {};
        this.tanggal        = "";
        this.date_range     = ref([]);
        this.data_pasien    = ref();
        this.no_tlp         = "";
        this.wajib_kontrol  = false;
        this.kontrol        = "";
        this.antrian        = ref()
        this.posisi         = 1
    }
    //-------------------------------------------------------------
    lanjut(){
        if(this.posisi >= 5){
            this.posisi = 5;
        }else{
            this.posisi += 1;
        }
    }
    kembali(){
        if(this.posisi == 1){
            this.posisi = 1;
        }else{
            this.posisi -= 1;
        }
    }
    kirim(){
        this.errors = {};

        if(!this.poli){
            this.errors.poli = 'Silahkan pilih terlebih dahulu Poli yang anda tuju!!';
            this.$toast.add({ severity: 'warn',  summary: 'Peringantan', detail: "Silahkan pilih terlebih dahulu Poli yang anda tuju!!", life: 4000 });
        }

        if(!this.tanggal){
            this.errors.tanggal = 'Silahkan pilih terlebih dahulu Tanggal pemeriksaan anda!!';
            this.$toast.add({ severity: 'warn',  summary: 'Peringantan', detail: "Silahkan pilih terlebih dahulu Tanggal pemeriksaan anda!!", life: 4000 });
        }

        if(this.wajib_kontrol && !this.kontrol){
            this.errors.kontrol = 'Silahkan isi terlebih dahulu nomor surat kontrol anda!!';
            this.$toast.add({ severity: 'warn',  summary: 'Peringantan', detail: "Silahkan isi terlebih dahusu nomor surat kontrol!!", life: 4000 });
        }

        if (this.poli && this.tanggal && (this.wajib_kontrol ? this.kontrol : true)) {
            this.getAntrian()
        }
        
    }
    //-------------------------------------------------------------


    //-------------------------------------------------------------
    async getRM(){
        this.isLoading = true;
        let url  = '/get/rm';
    
        const formData  = new FormData();
        formData.append('user', this.$store.state.token.user);
        formData.append('key', this.$store.state.token.key);
        formData.append('no_rm', this.no_rm);

        if(this.jenis=='BPJS'){
            url = '/get/rm_bpjs';
            formData.append('no_bpjs', this.no_bpjs);
        }
        await ax.post(url, formData).then((res : any ) => {
            this.isLoading = false;
            if(res.data.status){
                this.data_pasien = res.data.data;
                this.lanjut();
            }else{
                this.confirm.require({
                    group: 'headless',
                    message: res.data.msg,
                    header: 'Info',
                });
            }
            console.log(res);
        }).catch((err : any) => {
            this.isLoading = false;
            //this.$toast.add({ severity: 'error',  summary: 'Error', detail: err.message, life: 6000 });

            this.confirm.require({
                group: 'headless',
                message: err.message,
                header: 'Info',
            });
            console.log(err)
        })
    }
    //-------------------------------------------------------------

    //-------------------------------------------------------------
    async getDate(kode_poli : any){
        this.tanggal = "";

        this.isLoading = true;
        let url  = '/get/date_range/umum';
    
        const formData  = new FormData();
        formData.append('user', this.$store.state.token.user);
        formData.append('key', this.$store.state.token.key);
        formData.append('kode_poli', kode_poli);

        if(this.jenis=='BPJS'){
            url = '/get/date_range/bpjs';
            formData.append('no_rm', this.no_rm);
            formData.append('no_bpjs', this.no_bpjs);
        }

        await ax.post(url, formData).then((res : any ) => {
            this.isLoading = false;
            if(res.data.status){
                if(this.jenis == 'UMUM'){
                    this.date_range = res.data.data;
                }else if(this.jenis == 'BPJS'){
                    this.date_range = res.data.data.date_range;
                    this.wajib_kontrol = res.data.data.wajib_kontrol;
                }
                
                console.log(res.data.data)
            }else{
                this.confirm.require({
                    group: 'headless',
                    message: res.data.msg,
                    header: 'Info',
                });
            }
            console.log(res);
        }).catch((err : any) => {
            this.isLoading = false;
            //this.$toast.add({ severity: 'error',  summary: 'Error', detail: err.message, life: 6000 });
            this.confirm.require({
                group: 'headless',
                message: err.message,
                header: 'Info',
            });
            console.log(err)
        })
    }
    //-------------------------------------------------------------



    //-------------------------------------------------------------
    async getAntrian(){
        this.error = '';

        this.isLoading = true;
        const url  = '/get/antrian';
    
        const formData  = new FormData();
        formData.append('user', this.$store.state.token.user);
        formData.append('key', this.$store.state.token.key);
        
        formData.append('jenis', this.jenis);
        formData.append('no_rm', this.no_rm);
        formData.append('kode_poli', this.poli.kode_poli);
        formData.append('tgl_periksa', this.tanggal);
        formData.append('no_tlp', this.no_tlp);
        formData.append('no_bpjs', this.no_bpjs);
        formData.append('no_kontrol', this.kontrol);
        formData.append('sumber', "WEB");

        await ax.post(url, formData).then((res : any ) => {
            this.isLoading = false;
            console.log('ok-------------------------------------')
            if(res.data.status){
                if(res.data.response.status){
                    this.antrian = res.data.response.data;
                    this.lanjut();
                }else{
                    console.log(res.data.response.log);
                    this.error = res.data.response.msg;
                    this.confirm.require({
                        group: 'headless',
                        message: res.data.response.msg,
                        header: 'Info',
                    });
                }
            }else{
                this.error = res.data.msg;
                this.confirm.require({
                    group: 'headless',
                    message: res.data.msg,
                    header: 'Info',
                });
            }
            console.log(res.data.log);
        }).catch((err : any) => {
            console.log('error-------------------------------------')
            this.isLoading = false;
            this.error = err.message;
            this.confirm.require({
                group: 'headless',
                message: err.message,
                header: 'Info',
            });
            console.log(err)
        })
    }
    //-------------------------------------------------------------


    //-------------------------------------------------------------
    async getSyarat(){
        this.isLodingSyarat = true;
        await ax.get('/get/syarat/online/rajal').then((res : any ) => {
            console.log(res.data)
            if(res.data.status){
                this.syarat = res.data.data;
            }else{
                this.syarat = this.$store.state.syarat.list;
            }
            this.isLodingSyarat = false;
        }).catch((err : any) => {
            this.isLodingSyarat = false;
            console.log(err.message)
        })
    }
    //-------------------------------------------------------------



    //-------------------------------------------------------------
    formatDate(dateString: string): string {
        try {
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${(day < 10 ? '0' : '') + day}-${(month < 10 ? '0' : '') + month}-${year}`;
        }catch (error : any) {
            console.error('Error formatting date:', error.message);
            return dateString;
        }
    }
    //-------------------------------------------------------------
}