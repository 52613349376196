<p-panel :header="'tgl : ' + data.tgl_pemeriksaan +' | jam : '+ data.jam ">
    <!----------------------------------------------------------------------------------->

    <section class="m-0 area-print" :id="'area-'+data.antrian_poli" ref="contentToPrint">
        <antrian-item :data="data" />
    </section>
    <!----------------------------------------------------------------------------------->

    <!----------------------------------------------------------------------------------->
    <template #footer>
        <div class="flex flex-wrap align-items-center justify-content-between gap-3">

            <p-button text @click="onPrint"><font-awesome-icon :icon="['fas', 'print']"  /> Print</p-button>
            <p-button label="Simpan" outlined severity="success" rounded @click="simpan"
                :icon="is_loading ? 'pi pi-spin pi-spinner' : 'pi pi-download'" :disabled="is_loading">

            </p-button>

        </div>
    </template>
    <!----------------------------------------------------------------------------------->
</p-panel>

<!----------------------------------------------------------------------------------->
<vue3-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="false"
    :paginate-elements-by-height="1400" :filename="'Antrian-' + data.no_antri + '-' + data.tgl_boking" :pdf-quality="2"
    :manual-pagination="false" pdf-format="a7" pdf-orientation="portrait" pdf-content-width="74mm"
    @hasDownloaded="hasDownloaded($event)" ref="html2Pdf">
    <template v-slot:pdf-content>
        <!-- PDF Content Here -->
        <p class="mt-5 ">
            <antrian-item :data="data" />
        </p>
    </template>
</vue3-html2pdf>
<!----------------------------------------------------------------------------------->