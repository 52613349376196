import {Component, Vue, Prop, Ref} from 'vue-facing-decorator';
import { ref } from "vue";

@Component({
    name : 'app-sub-menu',
})
export default class SubMenu extends Vue {
    @Prop() menu = ref([]);
    @Ref('menu') menuRef!: { toggle: (event: Event) => void };
    @Prop() app_base_data = ref([]);

    private toggle(event: Event): void {
        this.menuRef.toggle(event);
    }

    get path(){
        return this.$route.path;
    }

  
}