<div class="grid justify-content-center mb-5">
    <div class="col-12 xl:col-8 p-0 pl-4 pr-4">
        <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center font-bold text-lg  text-left"> 
                {{title}} 
            </div>
            
            <div class="flex align-items-center justify-content-center ">
                <p-button @click="refresh"  icon="pi pi-replay"  severity="info" rounded outlined  />
            </div>

            <div v-if="paginator && posts.length > 0" class="hidden md:flex  align-items-center justify-content-center ">
                <!--------------------------------------------------------------------->
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center ">
                        <p-button @click="previous_page"   severity="info" rounded outlined :disabled="page==1">
                            <font-awesome-icon :icon="['fas', 'chevron-left']" />
                            sebelumnya
                        </p-button>
                    </div>
                    <div class="flex align-items-center justify-content-center pr-2 pl-2">
                        <p-button severity="info" rounded outlined  disabled>{{page}}</p-button>
                    </div>
                    <div class="flex align-items-center justify-content-center ">
                        <p-button @click="next_page"       severity="info" rounded outlined  :disabled="max_page">
                            selanjutnya
                            <font-awesome-icon :icon="['fas', 'chevron-right']" />
                        </p-button>
                    </div>
                </div>
                <!--------------------------------------------------------------------->
            </div>
        </div>
    </div>
    <div class="col-12 xl:col-8 p-0 pl-4 pr-4">
        <hr>
        <p-progressspinner v-if="isLoading" style="width: 50px; height: 50px" strokeWidth="8" fill="var(--surface-ground)"
            animationDuration=".5s" class="m-auto" />
    </div>
    <div class="col-12 p-0"></div>
    <!--Konten------------------------------------------------------------>
    <div class="col-12 xl:col-8  p-0 ">
        <!---------------------------------------------------------------->
        <div v-if="posts.length > 0" class="line-height-3 pl-3 pr-3">
            <div class="overflow-auto surface-overlay " :class="[posts.length > 3 ? 'h-30rem' : 'h-25rem']">
                    <!---------------------------------------------------->
                    <div  class="card mb-3 p-3"  v-for="(item, index) in posts" :key="index">
                        <div class="grid align-items-center justify-content-center p-0">
                            <div class="col-12 md:col-8 xl:col-9">
                                <span class="text-base font-bold">{{item.title.rendered}}</span><br>
                                <span class="text-sm font-italic"><font-awesome-icon :icon="['fas', 'clock']" /> Di post pada {{item.date}}</span>
                                <div class="text-base mt-2" v-html="item.excerpt.rendered"></div>
                            </div>
                            <div class="col-12 md:col-4 xl:col-3">
                                <p-button @click="goToLink(item.link)" icon="pi pi-chevron-right" label="Baca Selengkapnya!!" severity="info" rounded outlined class="w-full" />
                            </div>
                        </div>
                    </div>
                    <!---------------------------------------------------->
            </div>

            <!--------------------------------------------------------------------->
            <div v-if="paginator && posts.length > 0" class="flex md:hidden justify-content-between flex-wrap mt-2">
                <div class="flex align-items-center justify-content-center ">
                    <p-button @click="previous_page"   severity="info" rounded outlined :disabled="page==1">
                        <font-awesome-icon :icon="['fas', 'chevron-left']" />
                        sebelumnya
                    </p-button>
                </div>
                <div class="flex align-items-center justify-content-center pr-2 pl-2">
                    <p-button severity="info" rounded outlined  disabled>{{page}}</p-button>
                </div>
                <div class="flex align-items-center justify-content-center ">
                    <p-button @click="next_page"       severity="info" rounded outlined  :disabled="max_page">
                        selanjutnya
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </p-button>
                </div>
            </div>
            <!--------------------------------------------------------------------->

        </div> 
        <!---------------------------------------------------------------->
        <div v-else class="text-center font-bold">
            {{isLoading ? 'Loading...' : 'belum ada data...'}}
            {{errorMsg}}
        </div>
        <!---------------------------------------------------------------->
    </div>
    <!--Konten------------------------------------------------------------>
</div>