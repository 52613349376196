export default {
    setBaseData(state: any, newData: any) {
        state.app_name = newData.app_name;
        state.company_name = newData.company_name;
        state.tag_line_one = newData.tag_line_one;
        state.tag_line_two = newData.tag_line_two;
        state.description = newData.description;
        state.address = newData.address;
        state.email = newData.email;
        state.phone = newData.phone;
        state.fax = newData.fax;
        state.website = newData.website;
        state.instagram = newData.instagram;
        state.youtube = newData.youtube;
        state.main_icon = newData.main_icon;
        state.second_icon = newData.second_icon;
        state.img_background = newData.img_background;
        state.main_theme_color = newData.main_theme_color;
        state.second_theme_color = newData.second_theme_color;
        state.version = newData.version;
        state.config = newData.config;
    }
};
